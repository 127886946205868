import { Pipe, PipeTransform } from '@angular/core';
import { relationOptions } from 'src/app/modules/patient/models/relation.model';

@Pipe({
    name: 'contactRelation'
})
export class ContactRelationPipe implements PipeTransform {

    transform(value: unknown, ...args: unknown[]): unknown {
        const relation = relationOptions.find((relation) => relation.id == value);
        
        return (relation) ? relation.name : '';
    }

}
