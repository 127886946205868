import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientHeaderComponent } from 'src/app/modules/patient/components/patient-header/patient-header.component';
import { PatientWizardComponent } from 'src/app/modules/patient/components/patient-wizard/patient-wizard.component';
import { PatientViewDetailComponent } from 'src/app/modules/patient/components/patient-view-detail/patient-view-detail.component';
import { StickyHeaderComponent } from './sticky-header/sticky-header.component';
import { RouterModule } from '@angular/router';
import { StepsModule } from 'primeng/steps';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { SharedModule } from 'src/app/shared/shared.module';
import { SessionHeaderComponent } from '../session/components/session-header/session-header.component';
import { SessionWizardComponent } from 'src/app/modules/session/components/session-wizard/session-wizard.component';

@NgModule({
    declarations: [
		StickyHeaderComponent,
		PatientHeaderComponent, 
		PatientWizardComponent, 
		PatientViewDetailComponent,
        SessionHeaderComponent,
        SessionWizardComponent
	],
    imports: [
      	CommonModule,
		RouterModule,
		StepsModule,
		AvatarModule,
		SharedModule,
		ButtonModule
    ],
	exports: [
		StickyHeaderComponent
	]
})
export class HeaderModule { }
