<div class="layout-container" [ngClass]="containerClass">
    <app-sidebar></app-sidebar>
    <div class="layout-content-wrapper">
        <app-topbar></app-topbar>
        <app-sticky-header></app-sticky-header>
        <div class="layout-content">
            <router-outlet></router-outlet>
        </div>
    </div>
    <app-profilemenu></app-profilemenu>
    <app-tab-bar></app-tab-bar>
</div>
