import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Location } from '@angular/common';

@Component({
    selector: 'app-patient-wizard',
    templateUrl: './patient-wizard.component.html',
    styleUrls: ['./patient-wizard.component.scss']
})
export class PatientWizardComponent implements OnInit {
    public items: MenuItem[];

    @Input() activeIndex: number = 0;

    constructor( 
        private location: Location 
    ) { }

    ngOnChanges(changes: SimpleChanges) {
        if(this.activeIndex != undefined) this._reloadItems();
    }

    ngOnInit() {
        this._reloadItems();
    }

    goBack() {
        this.location.back();
    }

    private _reloadItems() {
        this.items = [
            { label: $localize`Información personal`, styleClass: (this.activeIndex > 0) ? 'icon-check' : '' },
            { label: $localize`Contacto`, styleClass: (this.activeIndex > 1) ? 'icon-check' : '' },
            { label: $localize`Datos clínicos`, styleClass: (this.activeIndex > 2) ? 'icon-check' : '' }
        ];
    }
}
