import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { AuthGuard } from './core/guards/auth.guard'
import { LegalNoticePage } from './shared/pages/legal-notice/legal-notice.page';

const routerOptions: ExtraOptions = {
    anchorScrolling: 'enabled'
};

const routes: Routes = [
    {
        path: '', component: AppLayoutComponent,
        children: [
            { path: '', pathMatch:'full', redirectTo: '/patient' },
            { path: 'patient', data: { appHeader: { title: $localize`Pacientes` } }, loadChildren: () => import('./modules/patient/patient.module').then((m) => m.PatientModule) },
            { path: 'session', data: { appHeader: { title: $localize`Sesiones` } }, loadChildren: () => import('./modules/session/session.module').then((m) => m.SessionModule) },
            { path: 'note', data: { appHeader: { title: $localize`Notas` } }, loadChildren: () => import('./modules/note/note.module').then((m) => m.NoteModule) },
        ],
        canActivate: [AuthGuard]
    },
    { 
        path: 'auth', 
        data: { breadcrumb: 'Auth' }, 
        loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
    },
    { path: 'legal-notice', component: LegalNoticePage },
    { path: 'notfound', loadChildren: () => import('./demo/components/notfound/notfound.module').then(m => m.NotfoundModule) },
    { path: '**', redirectTo: '/notfound' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
