<div class="layout-header surface-ground sticky top-0 pb-0 z-5"> 
    <div class="layout-header-wrapper">
        
        <!-- start: TITLE AND LOGOS -->
        <div class="grid hidden lg:flex">
            <div class="col-6 pt-0 pb-0">
                <div class="flex overflow-hidden my-0">
                    <div class="flex-shrink-0 flex align-items-center justify-content-center cursor-pointer" *ngIf="stickyHeader.showBackButton; else noBackButton" (click)="goBack()">
                        <i class="pi pi-angle-left text-2xl pr-2"></i>
                        <h5 class="text-3xl mt-0 mb-0 font-bold" i18n>{{ stickyHeader.title }}</h5>
                    </div>
                    <ng-template #noBackButton>
                        <div class="flex-shrink-0 flex align-items-center justify-content-center">
                            <h5 class="text-3xl mt-0 mb-0 font-bold" i18n>{{ stickyHeader.title }}</h5>
                        </div>
                    </ng-template>
                </div>
            </div>

            <!-- start: GENEREIC BUTTON -->
            <div class="col-6 pt-0 pb-0 text-right hidden lg:block" *ngIf="stickyHeader.button != undefined">
                <p-button [label]="stickyHeader.button.label" icon="pi pi-plus" styleClass="p-button" [routerLink]="stickyHeader.button.routerLink" i18n-label></p-button>
            </div>
            <!-- end: GENEREIC BUTTON -->
        </div>
        
        <div class="lg:hidden pt-0 pb-0">
            <div class="flex overflow-hidden my-0">
                <div class="flex-shrink-0 flex align-items-center justify-content-center cursor-pointer" *ngIf="stickyHeader.showBackButton; else noBackButton" (click)="goBack()">
                    <i class="pi pi-angle-left text-2xl pr-2"></i>
                    <h5 class="text-3xl mt-0 mb-0 font-bold" i18n>{{ stickyHeader.title }}</h5>
                </div>
                <ng-template #noBackButton>
                    <div class="flex-shrink-0 flex align-items-center justify-content-center">
                        <h5 class="text-3xl mt-0 mb-0 font-bold" i18n>{{ stickyHeader.title }}</h5>
                    </div>
                </ng-template>
                <div class="flex-grow-1 flex-shrink-1 flex align-items-center justify-content-end">
                    <img class="mt-0" style="width: 6rem; height: 3rem;" src="assets/media/logos/aceleradora_unoentrecienmil.png" alt="Uno entre cienmil">
                </div>
            </div>
        </div>
        <!-- end: TITLE AND LOGOS -->
        

        <!-- start: PATIENT HEADER COMPONENT -->
        <app-patient-header *ngIf="stickyHeader.patientHeader != undefined" [patientHeader]="stickyHeader.patientHeader"></app-patient-header>
        <!-- end: PATIENT HEADER COMPONENT -->

        <!-- start: SESSION HEADER COMPONENT -->
        <app-session-header *ngIf="stickyHeader.sessionHeader != undefined" [sessionHeader]="stickyHeader.sessionHeader"></app-session-header>
        <!-- end: SESSION HEADER COMPONENT -->

    </div>
</div> 