<div class="mt-3" *ngIf="patient">
    <div class="card" [ngClass]="{'card mt-3 mb-3 p-3': showCard}">
        <div class="flex flex-row align-items-center gap-2 cursor-pointer" (click)="goBack()" *ngIf="avatarBackButton; else noBackButton">
            <div class="flex sm:flex-column align-items-center sm:align-items-start gap-3 sm:gap-2 cursor-pointer">
                <i class="pi pi-angle-left text-2xl"></i>
            </div>
            <p-avatar [image]="getAvatar(patient)" styleClass="mr-2 patient-avatar" size="xlarge" shape="circle" [style]="{'width': '56px', 'height': '56px'}"></p-avatar>
            <div class="flex flex-row sm:flex-row justify-content-between align-items-center flex-1 gap-4 mt-1">
                <div class="flex flex-column align-items-start sm:align-items-start">
                    <div class="text-2xl font-semibold">{{ patient.user.first_name + ' ' + patient.user.last_name }}</div>
                    <div class="flex align-items-center gap-3">
                        <span class="flex align-items-center gap-2">{{ patient.birth | timeSince: 'months' }}</span>
                    </div>
                </div>
            </div>
            <div class="flex align-items-stretch flex-wrap" style="min-height: 50px" *ngIf="showPatientNumber">
                <div class="align-self-end flex align-items-center justify-content-center" style="min-height: 0px" *ngIf="patient.patientNumber">Núm. {{patient.patientNumber}}</div>
            </div>
        </div>
    </div>
    <ng-template #noBackButton>
        <div class="flex flex-row align-items-center gap-2">
            <p-avatar [image]="getAvatar(patient)" styleClass="mr-2 patient-avatar" size="xlarge" shape="circle" [style]="{'width': '56px', 'height': '56px'}"></p-avatar>
            <div class="flex flex-row sm:flex-row justify-content-between align-items-center flex-1 gap-4 mt-1">
                <div class="flex flex-column align-items-start sm:align-items-start">
                    <div class="text-2xl font-semibold">{{ patient.user.first_name + ' ' + patient.user.last_name }}</div>
                    <div class="flex align-items-center gap-3">
                        <span class="flex align-items-center gap-2">{{ patient.birth | timeSince: 'months' }}</span>
                    </div>
                </div>
            </div>
            <div class="flex align-items-stretch flex-wrap" style="min-height: 50px" *ngIf="showPatientNumber">
                <div class="align-self-end flex align-items-center justify-content-center" style="min-height: 0px" *ngIf="patient.patientNumber">Núm. {{patient.patientNumber}}</div>
            </div>
        </div>
        <div class="mt-4 ml-2" *ngIf="extendedDetail">
            <p><i class="pi pi-file" style="font-size: 1rem"></i><span class="ml-2">{{patient.historyNumber}}</span></p>
            <p><i class="pi pi-bookmark" style="font-size: 1rem"></i><span class="ml-2">{{patient.diagnosis.name}}</span></p>
            <p><i class="pi pi-phone" style="font-size: 1rem"></i><span class="ml-2">
                {{ patient.contacts[0].name + ' (' + (patient.contacts[0].relation | contactRelation)  + ') ' }}
                <span class="font-normal">{{ patient.contacts[0].phone }}</span>
            </span></p>
        </div>
    </ng-template>
</div>