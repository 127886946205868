export class AuthModel {
    
    public accessToken: string;
    public refreshToken: string;
    public expiresAt: Date;

    setAuth(auth: AuthModel) {
        this.accessToken = auth.accessToken;
        this.refreshToken = auth.refreshToken;
        this.expiresAt = auth.expiresAt;
    }

}
