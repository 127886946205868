import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { LocalStorageService } from '../services/local-storage.service';
import { AppConstants } from 'src/app/app.constants';
import { AuthModel } from 'src/app/modules/auth/models/auth.model';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private authService: AuthService,
        private localStorageService: LocalStorageService
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // add authorization header with jwt token if available
        if(this.localStorageService.getData(AppConstants.LOCAL_STORAGE_AUTH_TOKEN)){
            const authModel = JSON.parse(this.localStorageService.getData(AppConstants.LOCAL_STORAGE_AUTH_TOKEN)) as AuthModel;
            
            if(authModel && authModel.accessToken) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${authModel.accessToken}`
                    }
                });
            }
        }

        return next.handle(request).pipe(
            catchError((error:HttpErrorResponse | undefined) => {
                if(error?.status === 401) {
                    if(!environment.production) console.log(error);
                    this.authService.logout();
                    return throwError(() => error?.error?.errors.shift());
                }
              
                return throwError(() => error?.error?.errors.shift());
            })
        );
    }
}
