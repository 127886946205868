import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Injectable()
export class ResponseRemapInterceptor implements HttpInterceptor {

    constructor(
        private router: Router, 
        private messageService: MessageService
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // Sent inside the body directly the content of data
                    if(event.status === 200 && event.body['data'] != undefined) {
                        // Remap body.data to body directly
                        event = event.clone({ body: event.body.data });
                        
                        /*
                        * ALERT CONSIDERATION: we are supossing that every MANY-TO-MANY relation defined in directus
                        * returns a key with this format: { model_name_in_plural }_id.
                        * Examples: contacts_id, therapists_id
                        */
                        // Remap possible junction table relation
                        if(Array.isArray(event.body)){
                            for(let model of event.body) {
                                this._doRemap(model);
                            }
                        }
                        else if(typeof event.body === 'object') {
                            this._doRemap(event.body)
                        }
                    }
                }

                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                if(environment.production) {
                    this.router.navigate(['/']);
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: error.message });
                }
                return throwError(() => error);
            })
        );
    }


    /***************************
    ***** PRIVATE FUNCTIONS ****
    ****************************/
    private _doRemap(model:any) {
        Object.entries(model).forEach(([k_model, element]) => {
            if(element == null) return;
            if(Array.isArray(element)){
                let elements:any = [];
                for(let item of element){
                    Object.entries(item).forEach(([key, value]) => {
                        if(/s_id$/.test(key) && typeof value) {
                            elements.push(value);
                        }
                    });
                }
                // Remap if relation is not empty
                if(elements.length > 0) model[k_model] = elements
            }
            else if(typeof element === 'object') {
                this._doRemap(element);
            }
        });
    }
}
