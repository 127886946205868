import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { PatientHeader } from '../../interfaces/patient-header.interface';

@Component({
    selector: 'app-patient-header',
    templateUrl: './patient-header.component.html',
    styleUrls: ['./patient-header.component.scss']
})
export class PatientHeaderComponent {

	@Input() patientHeader:PatientHeader;

	constructor(
        private location: Location
	) {	}

    goBack() {
        this.location.back();
    }

}