import { Component, Input } from '@angular/core';
import { SessionHeader } from '../../interfaces/session-header.interface';
import { Location } from '@angular/common';

@Component({
    selector: 'app-session-header',
    templateUrl: './session-header.component.html',
    styleUrls: ['./session-header.component.scss']
})
export class SessionHeaderComponent {

    @Input() sessionHeader:SessionHeader;

	constructor(
        private location: Location
	) {	}

    goBack() {
        this.location.back();
    }
}
