import { Location } from '@angular/common';
import { Component, Input, SimpleChanges } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'app-session-wizard',
    templateUrl: './session-wizard.component.html',
    styleUrls: ['./session-wizard.component.scss']
})
export class SessionWizardComponent {
    public items: MenuItem[];

    @Input() activeIndex: number = 0;

    constructor( 
        private location: Location 
    ) { }

    ngOnChanges(changes: SimpleChanges) {
        if(this.activeIndex != undefined) this._reloadItems();
    }

    ngOnInit() {
        this._reloadItems();
    }

    goBack() {
        this.location.back();
    }

    private _reloadItems() {
        this.items = [
            { label: $localize`Antes`, styleClass: (this.activeIndex > 0) ? 'icon-check' : '' },
            { label: $localize`Durante`, styleClass: (this.activeIndex > 1) ? 'icon-check' : '' },
            { label: $localize`Después`, styleClass: (this.activeIndex > 2) ? 'icon-check' : '' }
        ];
    }
}
