import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { DirectusQueryParams, setPathParams } from 'src/app/shared/interfaces/directus-query-params.interface';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DirectusService<T> {

    constructor(
        private httpClient: HttpClient
    ) { }

    public index(endpoint: string, apiParams: DirectusQueryParams): Observable<Array<T>> {
        return this.httpClient.get<Array<T>>(`${environment.apiHost}${endpoint}`, { params: setPathParams(apiParams) }).pipe(
            map((res) => {
                return res;
            }),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }

    public create(endpoint: string, params: any): Observable<T> {
        return this.httpClient.post<T>(`${environment.apiHost}${endpoint}`, params).pipe(
            map(res => {
                return res;
            }),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }
    
    public view(endpoint: string, id:string, apiParams: DirectusQueryParams): Observable<T> {
        return this.httpClient.get<T>(`${environment.apiHost}${endpoint}/${id}`, { params: setPathParams(apiParams) }).pipe(
            map(res => {
                return res;
            }),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }

    public patch(endpoint: string, id: string, params:any, apiParams?:DirectusQueryParams): Observable<T> {
        return this.httpClient.patch<T>(`${environment.apiHost}${endpoint}/${id}`, params, { params: (apiParams) ? setPathParams(apiParams) : undefined  }).pipe(
            map(res => {
                return res;
            }),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }

    public delete(endpoint: string, id:string): Observable<T> {
        return this.httpClient.delete<T>(`${environment.apiHost}${endpoint}/${id}`).pipe(
            map(res => {
                return res;
            }),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }
}
