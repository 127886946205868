import { Pipe, PipeTransform } from "@angular/core";

/**
 * Tell the duration since a given time
 */
@Pipe({
  name: "timeSince",
})
export class TimeSincePipe implements PipeTransform {
    
    transform(value: Date, precision: "years" | "months" | "days" = "years"): string {

        const today = new Date()
        const birth = new Date(value)
        
        // Get the Timestamp
        const todayTimeStamp = today.getTime();
        const birthTimeStamp = birth.getTime();

        // Check which timestamp is greater
        const diff = new Date(todayTimeStamp - birthTimeStamp);
        
        // Retrieve the date, month and year
        const calcFormatTmp = diff.getDate() + '-' + (diff.getMonth() + 1) + '-' + diff.getFullYear();
        // Convert to an array and store
        const calcFormat = calcFormatTmp.split('-');

        // Subtract each member of our array from the default date
        const daysPassed = Number(Math.abs(parseInt(calcFormat[0])) - 1);
        const monthsPassed = Number(Math.abs(parseInt(calcFormat[1])) - 1);
        const yearsPassed = Number(Math.abs(parseInt(calcFormat[2])) - 1970);

        // Set up custom text
        const yrsTxt = [ $localize`año`, $localize`años` ];
        const mnthsTxt = [ $localize`mes`, $localize`meses` ];
        const daysTxt = [ $localize`día`, $localize`días` ];

        // Display result with custom text using precision argument
        let result = '';
        let connectorYear = '';
        
        // Switch precision
        if(yearsPassed == 0 && monthsPassed == 0) precision = 'days';

        switch (precision) {
            case 'days':
                connectorYear = (monthsPassed > 0) ? ', ' : ' y ';
                result = ((yearsPassed == 1) ? yearsPassed + ' ' + yrsTxt[0] + connectorYear : (yearsPassed > 1) ? yearsPassed + ' ' + yrsTxt[1] + connectorYear : '') +
                        ((monthsPassed == 1) ? monthsPassed + ' ' + mnthsTxt[0] + ' y ' : (monthsPassed > 1) ? monthsPassed + ' ' + mnthsTxt[1] + ' y ' : '') +
                        ((daysPassed == 1) ? daysPassed + ' ' + daysTxt[0] : (daysPassed > 1) ? daysPassed + ' ' + daysTxt[1] : '');
                break;
            case 'months':
                connectorYear = (monthsPassed > 0) ? ' y ' : '';
                result = ((yearsPassed == 1) ? yearsPassed + ' ' + yrsTxt[0] + connectorYear : (yearsPassed > 1) ? yearsPassed + ' ' + yrsTxt[1] + connectorYear : '') +
                        ((monthsPassed == 1) ? monthsPassed + ' ' + mnthsTxt[0] : (monthsPassed > 1) ? monthsPassed + ' ' + mnthsTxt[1] + ' ' : '');
                break;
            default:
                result = ((yearsPassed == 1) ? yearsPassed + ' ' + yrsTxt[0] + ' ' : (yearsPassed > 1) ? yearsPassed + ' ' + yrsTxt[1] + ' ' : '');
        }        

        return result.trim();
    }
}
